define("mvcs/components/ui/ui-box/ui-box-icon", ["exports", "ember-junkdrawer/components/ui/ui-box/ui-box-icon"], function (_exports, _uiBoxIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiBoxIcon.default;
    }
  });
});