define("mvcs/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CtxDqdBR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\",true],[8],[0,\"\\n  \"],[1,[23,0,[\"model\",\"title\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/templates/head.hbs"
    }
  });

  _exports.default = _default;
});