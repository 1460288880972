define("mvcs/validators/device", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      min: 1,
      max: 255
    })],
    device_id: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      min: 1,
      max: 30
    })]
  };
  _exports.default = _default;
});