define("mvcs/components/loading-table", ["exports", "mvcs-kit/components/loading-table"], function (_exports, _loadingTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _loadingTable.default;
    }
  });
});