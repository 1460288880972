define("mvcs/pages/anon/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RDcZKnp9",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Login\"],null],false],[5,\"anon/login/page\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/anon/login/template.hbs"
    }
  });

  _exports.default = _default;
});