define("mvcs/pages/vehicles/index/page/here/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vhx799ix",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[5,\"bs-card\",[[12,\"class\",\"mb-4\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    here?\\n    \"],[7,\"ul\",true],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/index/page/here/template.hbs"
    }
  });

  _exports.default = _default;
});