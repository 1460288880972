define("mvcs/services/pubnub", ["exports", "mvcs-kit/services/pubnub", "mvcs/config/environment", "pubnub"], function (_exports, _pubnub, _environment, _pubnub2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PubnubService extends _pubnub.default {
    constructor() {
      super(...arguments);
      this.client = new _pubnub2.default({
        publishKey: _environment.default.pubnub.publishKey,
        subscribeKey: _environment.default.pubnub.subscribeKey,
        uuid: this.currentUser.user.id
      });
      this.client.addListener({
        status: this.status.bind(this),
        message: this.message.bind(this),
        presence: this.presence.bind(this)
      });
    }

  }

  _exports.default = PubnubService;
});