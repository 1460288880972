define("mvcs/components/events/list", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Events::List::Filters
    @filters={{this.filters}}
    @devices={{this.devices}}
    @onFilterChange={{fn this.updateFilter}}
    ...attributes
  />
  {{#if this.isEventsLoading}}
    <LoadingTable />
  {{else if this.isEventsEmpty}}
    <Events::List::Empty />
  {{else}}
    <Events::List::List @events={{this.events}} />
  {{/if}}
  */
  {
    id: "4EhKTOyN",
    block: "{\"symbols\":[\"&attrs\"],\"statements\":[[5,\"events/list/filters\",[[13,1]],[[\"@filters\",\"@devices\",\"@onFilterChange\"],[[23,0,[\"filters\"]],[23,0,[\"devices\"]],[28,\"fn\",[[23,0,[\"updateFilter\"]]],null]]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEventsLoading\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loading-table\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isEventsEmpty\"]]],null,{\"statements\":[[0,\"  \"],[5,\"events/list/empty\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"events/list/list\",[],[[\"@events\"],[[23,0,[\"events\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/events/list.hbs"
    }
  });

  let EventsIndexPage = (_class = (_temp = class EventsIndexPage extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "events", _descriptor2, this);

      _initializerDefineProperty(this, "devices", _descriptor3, this);

      _initializerDefineProperty(this, "filters", _descriptor4, this);

      this.loadEvents.perform();
      this.loadDevices.perform();
    }

    get isEventsLoading() {
      return this.events === null;
    }

    get isEventsEmpty() {
      return this.events === false;
    }

    updateFilter(filter, value) {
      let filters = { ...this.filters
      };

      if (!value) {
        delete filters[filter];
      } else {
        filters[filter] = value;
      }

      this.filters = filters;
      this.events = null;
      this.loadEvents.perform();
    }

    *loadDevices() {
      this.devices = yield this.store.findAll('device');
    }

    *loadEvents() {
      let query = {};

      if (this.args.device) {
        query.device = this.args.device.id;
      }

      query = { ...query,
        ...this.filters
      };
      const events = yield this.store.query('event', query);

      if (!events || !events.length) {
        this.events = false;
      } else {
        this.events = events;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "events", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "devices", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        media: 'has_any'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadDevices", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadDevices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEvents", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadEvents"), _class.prototype)), _class);
  _exports.default = EventsIndexPage;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EventsIndexPage);
});