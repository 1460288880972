define("mvcs/pages/anon/login/route", ["exports", "mvcs/routes/abstract/abstract-un-authenticated"], function (_exports, _abstractUnAuthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginRoute extends _abstractUnAuthenticated.default {}

  _exports.default = LoginRoute;
});