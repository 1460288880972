define("mvcs/components/app-title/simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="bg-white pt-4 pb-0 shadow-sm">
    <div class="px-4">
      <h3 class="mb-2 text-grey-500 font-weight-light {{@class}}">
        {{yield}}
      </h3>
    </div>
  </div>
  */
  {
    id: "Vwqa7s5S",
    block: "{\"symbols\":[\"@class\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bg-white pt-4 pb-0 shadow-sm\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"px-4\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[11,\"class\",[29,[\"mb-2 text-grey-500 font-weight-light \",[23,1,[]]]]],[8],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/app-title/simple.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});