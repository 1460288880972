define("mvcs/components/ui/ui-box/ui-box-list", ["exports", "ember-junkdrawer/components/ui/ui-box/ui-box-list"], function (_exports, _uiBoxList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiBoxList.default;
    }
  });
});