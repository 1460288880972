define("mvcs/pages/user/page/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZRLMPIZK",
    "block": "{\"symbols\":[\"@profile\",\"@user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"mt-4 rounded shadow overflow-hidden user bg-white\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header p-4 bg-purple-800 text-white\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h5\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n      \"],[1,[23,1,[\"email\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"picture\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"text-center avatar\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"alt\",\"User\"],[10,\"class\",\"thumbnail img-responsive rounded-circle shadow\"],[11,\"src\",[23,1,[\"picture\"]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n      \"],[7,\"h5\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n        \"],[1,[28,\"moment-format\",[[23,2,[\"date_joined\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"text-grey-500\"],[8],[0,\"\\n        Date Joined\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/user/page/profile/template.hbs"
    }
  });

  _exports.default = _default;
});