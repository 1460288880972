define("mvcs/validators/organization", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      max: 40
    })],
    features: (0, _validators.validateLength)({
      allowBlank: true
    }),
    clientLabel: [(0, _validators.validateLength)({
      min: 1
    })],
    clientLabelPlural: [(0, _validators.validateLength)({
      min: 1
    })]
  };
  _exports.default = _default;
});