define("mvcs/pages/organization/route", ["exports", "mvcs/routes/abstract/abstract-authenticated"], function (_exports, _abstractAuthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrganizationRoute extends _abstractAuthenticated.default {}

  _exports.default = OrganizationRoute;
});