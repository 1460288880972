define("mvcs/pages/organization/team/page/invite-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/pueAJ2I",
    "block": "{\"symbols\":[\"Section\",\"Card\",\"g\",\"invite\",\"@onInviteRemove\",\"@invites\"],\"statements\":[[5,\"form/section\",[],[[\"@class\"],[\"mb-4\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"help\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h5\",true],[8],[0,\"\\n      Outstanding Invites\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Users that have been invited to your organization, but have not joined yet.\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"form\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-card\",[[12,\"class\",\"rounded shadow-sm\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"list-group\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"            \"],[6,[23,3,[\"item\"]],[[12,\"class\",\"d-flex justify-content-between\"]],[[],[]],{\"statements\":[[0,\"\\n              \"],[5,\"organization/team/page/invite\",[],[[\"@invite\",\"@onInviteRemove\"],[[23,4,[]],[23,5,[]]]]],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[4]},{\"statements\":[[0,\"            \"],[6,[23,3,[\"item\"]],[[12,\"class\",\"text-center\"]],[[],[]],{\"statements\":[[0,\"\\n              \"],[7,\"p\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n                No Pending Invites\\n              \"],[9],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"]],\"parameters\":[3]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/team/page/invite-list/template.hbs"
    }
  });

  _exports.default = _default;
});