define("mvcs/components/ui/ui-box/ui-box-image", ["exports", "ember-junkdrawer/components/ui/ui-box/ui-box-image"], function (_exports, _uiBoxImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiBoxImage.default;
    }
  });
});