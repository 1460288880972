define("mvcs/components/loading-card", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsCard class={{@classNames}} as |Card|>
    <Card.body>
      <ContentLoader>
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10"></rect>
        <rect x="20" y="20" rx="3" ry="3" width="220" height="10"></rect>
        <rect x="20" y="40" rx="3" ry="3" width="170" height="10"></rect>
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10"></rect>
        <rect x="20" y="80" rx="3" ry="3" width="200" height="10"></rect>
        <rect x="20" y="100" rx="3" ry="3" width="80" height="10"></rect>
      </ContentLoader>
    </Card.body>
  </BsCard>
  */
  {
    id: "cyq/e+7h",
    block: "{\"symbols\":[\"Card\",\"@classNames\"],\"statements\":[[5,\"bs-card\",[[12,\"class\",[23,2,[]]]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"content-loader\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"rect\",true],[10,\"x\",\"0\"],[10,\"y\",\"0\"],[10,\"rx\",\"3\"],[10,\"ry\",\"3\"],[10,\"width\",\"250\"],[10,\"height\",\"10\"],[8],[9],[0,\"\\n      \"],[7,\"rect\",true],[10,\"x\",\"20\"],[10,\"y\",\"20\"],[10,\"rx\",\"3\"],[10,\"ry\",\"3\"],[10,\"width\",\"220\"],[10,\"height\",\"10\"],[8],[9],[0,\"\\n      \"],[7,\"rect\",true],[10,\"x\",\"20\"],[10,\"y\",\"40\"],[10,\"rx\",\"3\"],[10,\"ry\",\"3\"],[10,\"width\",\"170\"],[10,\"height\",\"10\"],[8],[9],[0,\"\\n      \"],[7,\"rect\",true],[10,\"x\",\"0\"],[10,\"y\",\"60\"],[10,\"rx\",\"3\"],[10,\"ry\",\"3\"],[10,\"width\",\"250\"],[10,\"height\",\"10\"],[8],[9],[0,\"\\n      \"],[7,\"rect\",true],[10,\"x\",\"20\"],[10,\"y\",\"80\"],[10,\"rx\",\"3\"],[10,\"ry\",\"3\"],[10,\"width\",\"200\"],[10,\"height\",\"10\"],[8],[9],[0,\"\\n      \"],[7,\"rect\",true],[10,\"x\",\"20\"],[10,\"y\",\"100\"],[10,\"rx\",\"3\"],[10,\"ry\",\"3\"],[10,\"width\",\"80\"],[10,\"height\",\"10\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/loading-card.hbs"
    }
  });

  class LoadingCard extends _component.default {}

  _exports.default = LoadingCard;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LoadingCard);
});