define("mvcs/pages/vehicles/index/page/device/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+XCK1ek6",
    "block": "{\"symbols\":[\"card\",\"@device\"],\"statements\":[[5,\"bs-card\",[[12,\"class\",\"mb-4\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h5\",true],[10,\"class\",\"card-title d-flex align-content-center\"],[8],[0,\"\\n      \"],[5,\"device/badge-online\",[],[[\"@isOnline\"],[[23,0,[\"isOnline\"]]]]],[0,\"\\n      \"],[1,[23,2,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[23,2,[\"description\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-sm\"],[8],[0,\"\\n      Created:\\n      \"],[1,[28,\"moment-format\",[[23,2,[\"created\"]]],null],false],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"currentLocation\"]]],null,{\"statements\":[[0,\"        Current Location:\"],[1,[23,0,[\"currentLocation\"]],false],[0,\",\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default btn-sm\"]],[[\"@route\",\"@model\"],[\"vehicles.detail\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"pencil\"]]],[0,\"\\n      View\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-default btn-sm\"]],[[\"@route\",\"@model\"],[\"vehicles.edit\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"pencil\"]]],[0,\"\\n      Edit\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/index/page/device/template.hbs"
    }
  });

  _exports.default = _default;
});