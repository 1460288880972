define("mvcs/pages/invite-accept/page/accept/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "93TxLPQH",
    "block": "{\"symbols\":[\"Card\",\"@invite\"],\"statements\":[[5,\"bs-card\",[[12,\"class\",\"shadow rounded-lg\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Hi\\n    \"],[1,[23,2,[\"name\"]],false],[0,\"\\n    ,\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    Welcome to MVCS!\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    You've been invited to join the\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      \"],[1,[23,2,[\"organization_name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    team.\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@disabled\",\"@onClick\",\"@type\"],[[23,0,[\"isLoading\"]],[28,\"action\",[[23,0,[]],[23,0,[\"acceptInvite\"]]],null],\"primary\"]],{\"statements\":[[0,\"\\n      \"],[1,[23,0,[\"buttonText\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/invite-accept/page/accept/template.hbs"
    }
  });

  _exports.default = _default;
});