define("mvcs/pages/invite-accept/page/error-missing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S66cqAbR",
    "block": "{\"symbols\":[\"Card\",\"Cbody\"],\"statements\":[[5,\"bs-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"title\"]],[[12,\"class\",\"font-weight-bold text-center text-uppercase\"]],[[],[]],{\"statements\":[[0,\"\\n      No Invitation Found\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"text\"]],[[12,\"class\",\"text-center text-grey-500\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"em\",true],[8],[0,\"\\n        Please contact your organization owner for more information.\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/invite-accept/page/error-missing/template.hbs"
    }
  });

  _exports.default = _default;
});