define("mvcs/pages/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P0cpQk3M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center m-2\"],[8],[0,\"\\n  \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\",\"@size\"],[\"cocktail\",\"fal\",\"4x\"]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      We're sorry! An error has occurred, causing the application to crash. Our team has been notified and we're investigating\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"/dashboard\"],[8],[0,\"\\n        Dashboard\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/error/template.hbs"
    }
  });

  _exports.default = _default;
});