define("mvcs/helpers/inarray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inarray = inarray;
  _exports.default = void 0;

  function inarray([array, key, value]) {
    return array.filter(i => {
      let searchedValue = Ember.get(i, key);

      if (Ember.typeOf(searchedValue) === 'undefined') {
        return false;
      }

      return i[key] === value;
    });
  }

  var _default = Ember.Helper.helper(inarray);

  _exports.default = _default;
});