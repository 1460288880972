define("mvcs/utils/abstract-route", ["exports", "mvcs-kit/utils/abstract-route"], function (_exports, _abstractRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _abstractRoute.default;
    }
  });
});