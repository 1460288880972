define("mvcs/pages/organization/team/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+nSdOmBH",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[28,\"page-title\",[\"Team\"],null],false],[0,\"\\n\"],[5,\"organization/team/page\",[],[[\"@organization\",\"@users\",\"@invites\"],[[23,1,[\"organization\"]],[23,1,[\"users\"]],[23,1,[\"invites\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/team/template.hbs"
    }
  });

  _exports.default = _default;
});