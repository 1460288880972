define("mvcs/components/form/option-label", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.label}}
  */
  {
    id: "/mutj4h5",
    block: "{\"symbols\":[],\"statements\":[[1,[23,0,[\"label\"]],false]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/form/option-label.hbs"
    }
  });

  class Badge extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tagName", '');
    }

    get label() {
      const {
        options,
        attr,
        key
      } = this.args;
      return options[key][attr];
    }

  }

  _exports.default = Badge;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Badge);
});