define("mvcs/pages/vehicles/edit/page/config/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G+5PftQ+",
    "block": "{\"symbols\":[\"Section\",\"Card\",\"value\",\"key\",\"@device\"],\"statements\":[[5,\"form/section\",[],[[\"@class\"],[\"mb-4\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"help\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h5\",true],[8],[0,\"\\n      Configuration\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Copy this into the device configuration file\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"form\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-card\",[[12,\"class\",\"rounded shadow-sm overflow-hidden\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"p-2 bg-dark text-white rounded\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,5,[\"config\"]]],null]],null,{\"statements\":[[0,\"            \"],[1,[23,4,[]],false],[0,\"=\"],[1,[23,3,[]],false],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[3,4]},null],[0,\"        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/edit/page/config/template.hbs"
    }
  });

  _exports.default = _default;
});