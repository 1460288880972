define("mvcs/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm/ember", "mvcs/config/environment"], function (_exports, Sentry, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function startSentry() {
    Sentry.init({ ..._environment.default.sentry,
      integrations: [new _ember.Ember()],

      beforeSend(event, hint) {
        let error = hint.originalException; // ignore aborted route transitions from the Ember.js router

        if (error && error.name === 'TransitionAborted') {
          return null;
        }

        if (error && error.name === 'component.get is not a function') {
          return null;
        }

        return event;
      }

    });
  }
});