define("mvcs/pages/vehicles/detail/page/events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xWZHqNnh",
    "block": "{\"symbols\":[\"Card\",\"@device\"],\"statements\":[[5,\"bs-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Events for\\n    \"],[1,[23,2,[\"name\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[5,\"events/list\",[[12,\"class\",\"mt-2\"]],[[\"@device\"],[[23,2,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/detail/page/events/template.hbs"
    }
  });

  _exports.default = _default;
});