define("mvcs/pages/application/route", ["exports", "ember-simple-auth-auth0/mixins/application-route-mixin", "@sentry/browser"], function (_exports, _applicationRouteMixin, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationRoute = (_class = (_temp = class ApplicationRoute extends Ember.Route.extend(_applicationRouteMixin.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "onboarding", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "events", _descriptor5, this);

      _initializerDefineProperty(this, "fetch", _descriptor6, this);
    }

    beforeModel() {
      try {
        this._setupFutureEvents();

        let promise = Ember.RSVP.resolve(super.beforeModel(...arguments));
        promise = promise.then(this._getUrlHashData.bind(this)).then(this._authenticateWithUrlHash.bind(this)).then(() => {
          return this.fetch.request(`me/account/`);
        }).then(data => {
          if (!data || data.detail) {
            return;
          }

          const {
            user,
            organization
          } = data;
          this.session.set('data.user', user);
          this.session.set('data.organization', organization);
        });
        return promise;
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    afterModel(model, {
      targetName
    }) {
      try {
        if (this.session.isAuthenticated) {
          this.currentUser.didSetupUser();
        } //
        // Don't do anything with this.


        if (targetName === 'invite-accept') {
          return;
        }

        if (this.session.isAuthenticated) {
          const onboarding = this.onboarding.isUserOnboarding();

          if (onboarding) {
            this.transitionTo(onboarding);
            return;
          }
        }

        if (targetName === 'home' && this.session.isAuthenticated) {
          this.transitionTo('dashboard');
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    sessionInvalidated() {
      this.router.transitionTo('anon.logout');
    }
    /**
     *  After user login, the anon.login route handles finalization of sessions
     *  and any redirection required after the fact. so we need to go back to that page
      * @returns {string}
     */


    get routeAfterAuthentication() {
      return 'dashboard';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onboarding", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "events", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetch", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ApplicationRoute;
});