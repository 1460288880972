define("mvcs/components/app-title/advanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="bg-white pt-4 pb-0 shadow-sm">
    <div class="px-4">
      <div class="d-flex">
        <div>
          <h3 class="mb-2 text-grey-500 font-weight-light {{@class}}">
            {{yield (hash header="blank-template")}}
          </h3>
        </div>
        <div class="ml-auto header-secondary">
          {{yield (hash secondary="blank-template")}}
        </div>
      </div>
    </div>
  </div>
  */
  {
    id: "+mg2YJy8",
    block: "{\"symbols\":[\"@class\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bg-white pt-4 pb-0 shadow-sm\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"px-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"h3\",true],[11,\"class\",[29,[\"mb-2 text-grey-500 font-weight-light \",[23,1,[]]]]],[8],[0,\"\\n          \"],[14,2,[[28,\"hash\",null,[[\"header\"],[\"blank-template\"]]]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ml-auto header-secondary\"],[8],[0,\"\\n        \"],[14,2,[[28,\"hash\",null,[[\"secondary\"],[\"blank-template\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/app-title/advanced.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});