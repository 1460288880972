define("mvcs/pages/application/controller", ["exports", "mvcs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_dec = Ember.computed('router.currentRouteName'), _dec2 = Ember.computed('router.currentRouteName'), _dec3 = Ember.computed('router.currentRouteName'), _dec4 = Ember.computed('router.currentRouteName'), (_class = (_temp = class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "routes", {
        //
        // Anon
        'connect.slack': 'anon',
        'connect.github': 'anon',
        'anon.login': 'anon',
        'anon.logout': 'anon',
        'invite-accept': 'anon',
        home: 'anon',
        error: 'anon',
        //
        // Onboarding
        'onboarding.organization': 'onboarding'
      });
    }

    get layout() {
      const currentRouteName = this.router.currentRouteName;

      if (this.routes[currentRouteName]) {
        return this.routes[currentRouteName];
      }

      return 'app';
    }

    get isAppLayout() {
      return this.layout === 'app';
    }

    get isOnboardingLayout() {
      return this.layout === 'onboarding';
    }

    get isAnonLayout() {
      return this.layout === 'anon';
    }

    error(error) {
      if (error.errors) {
        if (_environment.default.environment === 'qa' || _environment.default.environment === 'production') {
          if (parseInt(error.errors[0].status) === 404) {
            this.transitionTo('not-found');
          } else if (parseInt(error.errors[0].status) === 500) {
            this.transitionTo('error');
          }
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "layout", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "layout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAppLayout", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isAppLayout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isOnboardingLayout", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isOnboardingLayout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAnonLayout", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isAnonLayout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});