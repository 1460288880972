define("mvcs/helpers/date-range-matches", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function dateRangeMatches([date1, date2, granularity = 'day']) {
    return (0, _moment.default)(date1).isSame(date2, granularity);
  });

  _exports.default = _default;
});