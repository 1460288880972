define("mvcs/pages/vehicles/index/page/device/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DeviceSummaryComponent = (_class = (_temp = class DeviceSummaryComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "pubnub", _descriptor, this);

      _defineProperty(this, "channel_prefix", null);

      _defineProperty(this, "channel_command", null);

      _defineProperty(this, "channel_location", null);

      _defineProperty(this, "currentLocation", null);

      _defineProperty(this, "isOnline", null);

      const device = this.args.device;
      this.channel_command = device.channel_command;
      this.channel_location = device.channel_location;
      this.channel_prefix = device.channel_prefix;
      this.registerListeners();
      this.findDevice();
    }

    findDevice() {
      if (!this.channel_command) {
        return false;
      }

      this.pubnub.here(this.channel_command, this.here.bind(this));
    }

    here(status, results) {
      if (!results) {
        return false;
      }

      const occupants = results.channels[this.channel_command]['occupants'] || [];
      let found = false;
      occupants.forEach(occupant => {
        if (occupant.uuid === this.channel_prefix) {
          found = true;
          Ember.set(this, 'isOnline', true);
        }
      });

      if (!found) {
        Ember.set(this, 'isOnline', false);
      }
    }

    registerListeners() {
      this.pubnub.registerMessageListener(this.channel_location, this.new_location.bind(this));
      this.pubnub.registerPresenceListener(this.channel_command, this.new_presence.bind(this));
    }

    new_location(msg) {
      Ember.set(this, 'currentLocation', msg.message);
    }

    new_presence(msg) {
      if (msg.uuid !== this.channel_prefix) {
        return false;
      }

      if (msg.action === 'join') {
        Ember.set(this, 'isOnline', true);
      } else {
        Ember.set(this, 'isOnline', false);
      }
    }

    willDestroy() {
      this.pubnub.unRegisterPresenceListener(this.channel_command);
      this.pubnub.unRegisterMessageListener(this.channel_location);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pubnub", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = DeviceSummaryComponent;
});