define("mvcs/pages/events/detail/page/video/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-animated/motions/opacity", "ember-animated/motions/move"], function (_exports, _component, _emberConcurrencyDecorators, _opacity, _move) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EventVideoComponent = (_class = (_temp = class EventVideoComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "media", _descriptor2, this);

      _initializerDefineProperty(this, "selected", _descriptor3, this);

      this.loadMedia.perform();
    }

    get isMediaLoading() {
      return this.media === null;
    }

    get isMediaEmpty() {
      return this.media === false;
    }

    *selectedTransition({
      insertedSprites,
      removedSprites
    }) {
      yield insertedSprites.forEach(_opacity.fadeIn);
      yield removedSprites.forEach(_opacity.fadeOut);
    }

    *mediaTransition({
      removedSprites,
      keptSprites
    }) {
      yield removedSprites.forEach(_opacity.fadeOut);
      yield keptSprites.forEach(_move.default);
    }

    *loadMedia() {
      const media = yield this.store.query('media', {
        filter: {
          event: this.args.event.id,
          type: 'video'
        }
      });

      if (!media || !media.length) {
        this.media = false;
      } else {
        let list = Ember.A(media.toArray()).sortBy('channel');
        this.selected = list.firstObject;
        this.media = list.removeObject(this.selected);
      }
    }

    select(video) {
      this.media = Ember.A(this.media).addObject(this.selected).removeObject(video);
      this.selected = video;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "media", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selected", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadMedia", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadMedia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class);
  _exports.default = EventVideoComponent;
});