define("mvcs/pages/anon/login/page/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ib9BPasn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"id\",\"login-form\"],[3,\"did-insert\",[[28,\"action\",[[23,0,[]],[23,0,[\"initLogin\"]]],null]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/anon/login/page/form/template.hbs"
    }
  });

  _exports.default = _default;
});