define("mvcs/validators/invite", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      min: 1,
      max: 255
    })],
    role: (0, _validators.validateInclusion)({
      list: ['Admin', 'Member']
    }),
    email: [(0, _validators.validateFormat)({
      type: 'email'
    }), (0, _validators.validateLength)({
      min: 1,
      max: 254,
      message: '{description} is too long.'
    })]
  };
  _exports.default = _default;
});