define("mvcs/pages/organization/team/page/member-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qTt99lD0",
    "block": "{\"symbols\":[\"Section\",\"g\",\"user\",\"@users\"],\"statements\":[[5,\"form/section\",[],[[\"@class\"],[\"mb-4\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"help\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h5\",true],[8],[0,\"\\n      Team Members\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      These individuals can use MVCS.\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"form\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-card\",[[12,\"class\",\"rounded shadow-sm overflow-hidden p-3\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[5,\"list-group\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"            \"],[6,[23,2,[\"item\"]],[[12,\"class\",\"d-flex bg-none\"]],[[],[]],{\"statements\":[[0,\"\\n              \"],[5,\"organization/team/page/member\",[],[[\"@user\"],[[23,3,[]]]]],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},{\"statements\":[[0,\"            \"],[6,[23,2,[\"item\"]],[[12,\"class\",\"text-center\"]],[[],[]],{\"statements\":[[0,\"\\n              \"],[7,\"p\",true],[10,\"class\",\"mb-0\"],[8],[0,\"\\n                No Users\\n              \"],[9],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"]],\"parameters\":[2]}],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/team/page/member-list/template.hbs"
    }
  });

  _exports.default = _default;
});