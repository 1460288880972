define("mvcs/services/network", ["exports", "ember-network-state/services/network"], function (_exports, _network) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _network.default;
    }
  });
});