define("mvcs/components/layout/anon-page", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="w-60 page-type-anon__left">
    {{yield (hash left="blank-template")}}
  </div>
  <div class="w-40 page-type-anon__right">
    {{yield (hash right="blank-template")}}
  </div>
  */
  {
    id: "32EKkZGe",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"w-60 page-type-anon__left\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"left\"],[\"blank-template\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"w-40 page-type-anon__right\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"right\"],[\"blank-template\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/layout/anon-page.hbs"
    }
  });

  class AnonPage extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tagName", '');
    }

  }

  _exports.default = AnonPage;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnonPage);
});