define("mvcs/pages/invite-accept/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8gDz07Mj",
    "block": "{\"symbols\":[\"@invite\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[5,\"invite-accept/page/accept\",[],[[\"@invite\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"invite-accept/page/error-missing\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/invite-accept/page/template.hbs"
    }
  });

  _exports.default = _default;
});