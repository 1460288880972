define("mvcs/pages/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kiJBu6YB",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[1,[28,\"page-title\",[\"MVCS\"],null],false],[0,\"\\n\"],[5,\"head-layout\",[],[[],[]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"notifications\",\"hasAny\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"message-center\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"notifications\",\"queue\"]]],null,{\"statements\":[[0,\"      \"],[5,\"flash-message\",[],[[\"@flash\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isAppLayout\"]]],null,{\"statements\":[[0,\"  \"],[5,\"layout/app\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isAnonLayout\"]]],null,{\"statements\":[[0,\"  \"],[5,\"layout/anon\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isOnboardingLayout\"]]],null,{\"statements\":[[0,\"  \"],[5,\"layout/onboarding\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[5,\"new-version-notifier\",[],[[\"@updateMessage\",\"@updateInterval\",\"@showReload\"],[\"Reload to get the latest version of MVCS\",150000,true]]],[0,\"\\n\"],[5,\"ember-load-remover\",[],[[],[]]],[0,\"\\n\"],[5,\"intercom-io\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/application/template.hbs"
    }
  });

  _exports.default = _default;
});