define("mvcs/services/current-user", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrentUserService = (_dec = Ember.computed('session.data.authenticated.profile.sub'), _dec2 = Ember.computed('session.data.user.{id,username}'), _dec3 = Ember.computed('session.data.organization.@each'), _dec4 = Ember.computed.readOnly('organization.has_projects'), _dec5 = Ember.computed('session.data.authenticated.profile.sub'), (_class = (_temp = class CurrentUserService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "fetch", _descriptor3, this);

      _initializerDefineProperty(this, "intercom", _descriptor4, this);

      _initializerDefineProperty(this, "events", _descriptor5, this);

      _initializerDefineProperty(this, "fullStory", _descriptor6, this);

      _initializerDefineProperty(this, "orgHasProjects", _descriptor7, this);
    }

    get profile() {
      if (this.session.data.authenticated.profile) {
        return this.session.data.authenticated.profile;
      }

      return null;
    }
    /**
     * The API user
     */


    get user() {
      if (this.session.data.user) {
        return this.session.data.user;
      }

      return null;
    }
    /**
     * The API Organization
     */


    get organization() {
      if (this.session.data.organization) {
        return this.session.data.organization;
      }

      return null;
    }

    get defaultMapLocation() {
      return [-83.045753, 42.331429];
    }

    hasFlag(flag) {
      return this.organization.features.includes(flag);
    }

    setOrganization(organization) {
      this.session.set('data.organization', organization);
      this.setIntercomData();
    }

    updateOrganizationProp(key, val) {
      let path = `data.organization.${key}`;
      this.session.set(path, val);
    }

    didSetupUser() {
      this.setIntercomData();
      const user = this.user;
      const profile = this.profile;
      const organization = this.organization;
      let userData = {
        id: user.id,
        traits: {
          name: profile.name,
          email: profile.email
        }
      };
      let organizationData = {};

      if (organization) {
        organizationData = {
          id: this.organization.id,
          traits: {
            name: this.organization.name
          }
        };
      }

      this.events.identify(userData, organizationData);
      this.fullStory.identify(this.user.id, {
        displayName: profile.name,
        email: profile.email
      });
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user.id,
          email: profile.email
        });
      });
    }

    setIntercomData(company = false) {
      let {
        email,
        name
      } = this.get('session.data.authenticated.profile');
      let {
        id,
        date_joined
      } = this.user;

      if (!company) {
        company = this.intercomCompany;
      }

      let user = {
        id,
        date_joined,
        email,
        name,
        company
      };
      Ember.set(this, 'intercom.user', user);
    }

    get intercomCompany() {
      if (!this.organization) {
        return undefined;
      }

      return Ember.getProperties(this.organization, ['id', 'name']);
    }

    get username() {
      let username = this.get('session.data.authenticated.profile.sub');

      if (Ember.isEmpty(username)) {
        return null;
      }

      return username.replace('|', '-');
    }

    async switchOrganization(organizationId) {
      let payload = {
        organization_id: organizationId
      }; // TODO figure out how to do error handling.

      const response = await this.fetch.post('/me/organization_switch', payload);
      await this.setOrganization(response.data); // Do a hard refresh of all the data in the system

      window.location.href = '/dashboard';
    }

    async acceptInvitation(invite, email) {
      const payload = {
        id: invite,
        email: email
      };
      return await this.fetch.request('join/invite_accept/', payload);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intercom", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "events", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fullStory", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "profile", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "profile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "user"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "organization", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "organization"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "orgHasProjects", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "username", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "username"), _class.prototype)), _class));
  _exports.default = CurrentUserService;
});