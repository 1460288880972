define("mvcs/pages/vehicles/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uo6pZLXk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Vehicles\"],null],false],[5,\"vehicles/edit/page\",[],[[\"@device\"],[[23,0,[\"model\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/edit/template.hbs"
    }
  });

  _exports.default = _default;
});