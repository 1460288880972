define("mvcs/pages/organization/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DZRqGRbz",
    "block": "{\"symbols\":[\"Title\",\"nav\"],\"statements\":[[1,[28,\"page-title\",[\"Organizaiton\"],null],false],[0,\"\\n\"],[5,\"app-title/advanced\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Organization\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"secondary\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-nav\",[],[[\"@justified\",\"@stacked\",\"@fill\"],[false,false,false]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,2,[\"link-to\"]],[],[[\"@route\"],[\"organization.index\"]],{\"statements\":[[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"folders\",\"fal\"]]],[0,\"\\n          Settings\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,2,[\"link-to\"]],[],[[\"@route\"],[\"organization.team\"]],{\"statements\":[[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"user-plus\",\"fal\"]]],[0,\"\\n          Team\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[5,\"app-container\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/template.hbs"
    }
  });

  _exports.default = _default;
});