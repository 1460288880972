define("mvcs/adapters/application", ["exports", "ember-data", "mvcs/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    JSONAPIAdapter
  } = _emberData.default;
  let ApplicationAdapter = (_dec = Ember.computed('currentUser.organization', 'session.data.{organization,authenticated}'), (_class = (_temp = class ApplicationAdapter extends JSONAPIAdapter.extend(_dataAdapterMixin.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _defineProperty(this, "namespace", _environment.default.APP.API_NAMESPACE);

      _defineProperty(this, "host", _environment.default.APP.API_HOST);
    }

    get headers() {
      let headers = {
        'Content-Type': 'application/vnd.api+json'
      };

      if (this.session.isAuthenticated) {
        const data = this.get('session.data.authenticated');
        const {
          idToken,
          tokenType
        } = data;
        headers.Authorization = `${tokenType} ${idToken}`;
      }

      if (this.session.data.organization) {
        headers['X-Organization'] = this.session.data.organization.id;
      }

      return headers;
    }

    ensureResponseAuthorized(status
    /* ,headers, payload, requestData */
    ) {
      if (status === 401 && this.get('session.isAuthenticated')) {//this.get('session').invalidate();
      }
    }

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = super.buildURL(modelName, id, snapshot, requestType, query); // Append slashes to make Django happy.

      if (url.charAt(url.length - 1) !== '/') {
        url += '/';
      }

      return url;
    }
    /**
     * ensures all query params are passed as underscored props
     * @param query
     */


    sortQueryParams(query) {
      query = super.sortQueryParams(query);
      let newParams = {};

      if (Object.keys(query).length > 0) {
        for (let key in query) {
          newParams[Ember.String.decamelize(key)] = query[key];
        }
      }

      return newParams;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = ApplicationAdapter;
});