define("mvcs/models/user", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let User = (_dec = (0, _model.attr)('date'), _dec2 = (0, _model.attr)('date'), _dec3 = (0, _model.hasMany)('organization', {
    async: true
  }), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)(), _dec13 = (0, _model.attr)(), (_class = (_temp = class User extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "created", _descriptor, this);

      _initializerDefineProperty(this, "modified", _descriptor2, this);

      _initializerDefineProperty(this, "organization", _descriptor3, this);

      _initializerDefineProperty(this, "username", _descriptor4, this);

      _initializerDefineProperty(this, "email", _descriptor5, this);

      _initializerDefineProperty(this, "date_joined", _descriptor6, this);

      _initializerDefineProperty(this, "provider_type", _descriptor7, this);

      _initializerDefineProperty(this, "name", _descriptor8, this);

      _initializerDefineProperty(this, "avatar", _descriptor9, this);

      _initializerDefineProperty(this, "current_organization_role", _descriptor10, this);

      _initializerDefineProperty(this, "isActive", _descriptor11, this);

      _initializerDefineProperty(this, "preferences", _descriptor12, this);

      _initializerDefineProperty(this, "provider_info", _descriptor13, this);

      _defineProperty(this, "change_status", (0, _emberApiActions.memberAction)({
        path: 'change_status/',
        type: 'POST'
      }));

      _defineProperty(this, "change_organization_role", (0, _emberApiActions.memberAction)({
        path: 'change_organization_role/',
        type: 'POST'
      }));

      _defineProperty(this, "accept_invite", (0, _emberApiActions.memberAction)({
        path: 'accept_invite/',
        type: 'POST'
      }));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "created", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modified", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "organization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "username", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "date_joined", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "provider_type", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "avatar", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "current_organization_role", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "preferences", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "provider_info", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = User;
});