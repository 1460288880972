define("mvcs/pages/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yS0Inm78",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"w-50 rounded shadow bg-white py-4 mx-auto mt-4\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      This page can't be found.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn btn-primary\"]],[[\"@route\"],[\"dashboard\"]],{\"statements\":[[0,\"\\n      Dashboard\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});