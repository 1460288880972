define("mvcs/components/badge", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{class "bg-grey-200 text-grey-600 px-1 rounded" small=@small font-weight-bold=@bold text-lowercase=@lowercase}}>
    {{yield}}
  </div>
  */
  {
    id: "z0eKs57s",
    block: "{\"symbols\":[\"@lowercase\",\"@bold\",\"@small\",\"&default\"],\"statements\":[[7,\"div\",false],[3,\"class\",[\"bg-grey-200 text-grey-600 px-1 rounded\"],[[\"small\",\"font-weight-bold\",\"text-lowercase\"],[[23,3,[]],[23,2,[]],[23,1,[]]]]],[8],[0,\"\\n  \"],[14,4],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/badge.hbs"
    }
  });

  class Badge extends _component.default {}

  _exports.default = Badge;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Badge);
});