define("mvcs/pages/organization/team/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7WKOlxH2",
    "block": "{\"symbols\":[\"@users\",\"@organization\"],\"statements\":[[5,\"organization/team/page/member-list\",[],[[\"@users\"],[[23,1,[]]]]],[0,\"\\n\"],[5,\"organization/team/page/invite-new\",[],[[\"@organization\",\"@onInviteAdd\"],[[23,2,[]],[23,0,[\"onInviteAdd\"]]]]],[0,\"\\n\"],[5,\"organization/team/page/invite-list\",[],[[\"@organization\",\"@invites\",\"@onInviteRemove\"],[[23,2,[]],[23,0,[\"localInviteList\"]],[23,0,[\"onInviteRemove\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/team/page/template.hbs"
    }
  });

  _exports.default = _default;
});