define("mvcs/services/ui-global", ["exports", "ember-junkdrawer/services/ui-global"], function (_exports, _uiGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiGlobal.default;
    }
  });
});