define("mvcs/services/fetch", ["exports", "fetch", "mvcs/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * TODO: This is pretty janky. Find a contrib version of this?
   * https://github.com/tchak/ember-fetch-adapter
   * What are you calling janky?
   *
   * NOTE: This specifically avoids json-API format calls and only works with
   * basic data structures.
   */
  let FetchService = (_class = (_temp = class FetchService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _defineProperty(this, "namespace", _environment.default.APP.API_NAMESPACE);

      _defineProperty(this, "host", _environment.default.APP.API_HOST);
    }

    get headers() {
      let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      };

      if (this.session.isAuthenticated) {
        let {
          idToken,
          tokenType
        } = this.get('session.data.authenticated');
        headers.Authorization = `${tokenType} ${idToken}`;
      }

      if (this.currentUser.organization) {
        headers['X-Organization'] = this.currentUser.organization.id;
      }

      return headers;
    }

    get baseUrl() {
      return `${this.host}/${this.namespace}`;
    }
    /**
     * Add trailing slash, and deal with inability to remember to pass in slashes or not.
     * @param path
     * @returns {string}
     */


    buildUrl(path) {
      let url = this.baseUrl;

      if (!path.startsWith('/')) {
        url += '/';
      }

      url += path;

      if (!url.endsWith('/')) {
        url += '/';
      }

      return url;
    }
    /**
     * GET
     * @param path
     * @param params
     * @returns {*}
     */


    request(path, params = null, options = {}) {
      let url = this.buildUrl(path);

      if (params) {
        url = url + `?${this._createParams(params)}`;
      }

      let headers = Ember.getWithDefault(options, 'headers', {});
      headers = Object.assign(this.headers, headers);
      let config = {
        headers
      };

      if (Ember.getWithDefault(options, 'x-org-free', false)) {
        delete config.headers['X-Organization'];
      }

      return (0, _fetch.default)(url, config).then(res => {
        if (Ember.getWithDefault(options, 'raw', false)) {
          return res;
        } else {
          return res.json();
        }
      });
    }
    /**
     * POST
     * @param path
     * @param data
     * @returns {*}
     */


    post(path, data = {}, options = {}) {
      let url = this.buildUrl(path);
      let headers = this.headers;
      headers = Object.assign(headers, Ember.getWithDefault(options, 'headers', {}));

      if (Ember.getWithDefault(options, 'jsonApi', false)) {
        headers['Content-Type'] = 'application/vnd.api+json';
      }

      let config = {
        headers,
        method: 'POST',
        body: JSON.stringify(data)
      };
      return (0, _fetch.default)(url, config).then(res => {
        if (Ember.getWithDefault(options, 'raw', false)) {
          return res;
        } else {
          return res.json();
        }
      });
    }

    _createParams(params) {
      const searchParams = new URLSearchParams();
      Object.keys(params).forEach(key => searchParams.append(key, params[key]));
      return searchParams.toString();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FetchService;
});