define("mvcs/pages/organization/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SWlEESUy",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[28,\"page-title\",[\"Settings\"],null],false],[5,\"organization/index/page\",[],[[\"@organization\"],[[23,1,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/index/template.hbs"
    }
  });

  _exports.default = _default;
});