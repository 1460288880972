define("mvcs/services/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Handles coordinating various multi-stage onboarding efforts.
   */
  let OnboardingService = (_class = (_temp = class OnboardingService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "fetch", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);
    }

    /**
     * Returns false if the user is already completed onboarding
     * Returns a route the user should be at if the user has steps left.
     */
    isUserOnboarding() {
      //
      // User isn't even logged in...
      if (!this.session.isAuthenticated) {
        return 'anon.login';
      }

      const organization = this.currentUser.organization; //
      // User has an invite

      if (Ember.getWithDefault(this, 'session.data.invite.id', false)) {
        return 'invite-accept';
      } //
      // User Doesn't have an organization...


      if (organization === null || !organization.id) {
        return 'onboarding.organization';
      }

      return false;
    }
    /**
     * @param organization
     * @return {Promise<{id}|*|{error: boolean}|{error: *}>}
     */


    async creatNewOrganization(organization) {
      try {
        const results = await this.fetch.post('me/organization_create', organization);

        if (results && results['detail']) {
          return {
            error: results['detail']
          };
        }

        if (results && results.id) {
          this.currentUser.setOrganization(results);
        }

        return results;
      } catch (e) {
        console.log(e); // eslint-disable-line no-console

        return {
          error: true
        };
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = OnboardingService;
});