define("mvcs/components/form/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="row {{@class}}">
    <div class="col-md-3">
      <div class="p-4">
        {{yield (hash help="blank-template")}}
      </div>
    </div>
    <div class="col-md-9">
      {{yield (hash form="blank-template")}}
    </div>
  </div>
  */
  {
    id: "JUT9ynHf",
    block: "{\"symbols\":[\"@class\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"row \",[23,1,[]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"p-4\"],[8],[0,\"\\n      \"],[14,2,[[28,\"hash\",null,[[\"help\"],[\"blank-template\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-9\"],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"form\"],[\"blank-template\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/form/section.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});