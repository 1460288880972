define("mvcs/pages/vehicles/detail/page/debug/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YeAaECdl",
    "block": "{\"symbols\":[\"card\",\"@deviceState\"],\"statements\":[[5,\"bs-card\",[[12,\"class\",\"mt-4 mb-4\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Debug\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h6\",true],[8],[0,\"\\n      Device State\\n    \"],[9],[0,\"\\n    \"],[5,\"json-editor\",[],[[\"@json\",\"@search\",\"@history\",\"@mode\"],[[23,2,[]],false,false,\"view\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/detail/page/debug/template.hbs"
    }
  });

  _exports.default = _default;
});