define("mvcs/pages/events/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wpLaqFkV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Events\"],null],false],[0,\"\\n\"],[5,\"app-title/simple\",[],[[],[]],{\"statements\":[[0,\"\\n  Events\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"app-container\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"events/list\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/events/index/template.hbs"
    }
  });

  _exports.default = _default;
});