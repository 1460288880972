define("mvcs/components/layout/onboarding", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="page-type-onboarding d-flex flex-row">
    {{yield}}
  </div>
  */
  {
    id: "Y/H6PxwX",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-type-onboarding d-flex flex-row\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/layout/onboarding.hbs"
    }
  });

  class OnboardingPageLayout extends _component.default {}

  _exports.default = OnboardingPageLayout;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OnboardingPageLayout);
});