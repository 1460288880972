define("mvcs/mixins/flexable", ["exports", "ember-junkdrawer/mixins/flexable"], function (_exports, _flexable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _flexable.default;
    }
  });
});