define("mvcs/pages/organization/index/page/general/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zcp9rtHw",
    "block": "{\"symbols\":[\"Section\",\"provider\",\"Form\",\"Card\",\"@organization\"],\"statements\":[[5,\"form/section\",[],[[\"@class\"],[\"mb-4\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"help\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h5\",true],[8],[0,\"\\n      About\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Tell us a little about your organization.\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"form\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"changeset-provider\",[],[[\"@onSubmitSuccess\",\"@model\"],[[28,\"action\",[[23,0,[]],[23,0,[\"didSave\"]]],null],[23,5,[]]]],{\"statements\":[[0,\"\\n      \"],[5,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[23,2,[\"changeset\"]],[23,2,[\"submit\"]]]],{\"statements\":[[0,\"\\n        \"],[5,\"bs-card\",[[12,\"class\",\"rounded shadow-sm overflow-hidden p-3\"]],[[],[]],{\"statements\":[[0,\"\\n          \"],[6,[23,4,[\"body\"]],[[12,\"class\",\"p-1\"]],[[],[]],{\"statements\":[[0,\"\\n            \"],[6,[23,3,[\"element\"]],[],[[\"@property\",\"@label\"],[\"name\",\"Organization Name\"]]],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n          \"],[6,[23,4,[\"footer\"]],[[12,\"class\",\"p-1\"]],[[],[]],{\"statements\":[[0,\"\\n            \"],[5,\"bs-button\",[],[[\"@type\",\"@buttonType\"],[\"primary\",\"submit\"]],{\"statements\":[[0,\"\\n              \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"save\"]]],[0,\"\\n              Save\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[4]}],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/index/page/general/template.hbs"
    }
  });

  _exports.default = _default;
});