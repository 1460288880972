define("mvcs/transforms/sortable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function mutateChildren(node) {
    if (!node.children) {
      node.children = Ember.A();
      return node;
    }

    let {
      children
    } = node;

    if (Ember.typeOf(children) === 'array') {
      node.children = Ember.A(children);
      children.forEach(child => mutateChildren(child));
    }

    return node;
  }

  class SortableTransform extends _emberData.default.Transform {
    deserialize(serialized) {
      return serialized.map(mutateChildren);
    }

    serialize(deserialized) {
      return deserialized;
    }

  }

  _exports.default = SortableTransform;
});