define("mvcs/components/form/section-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p class="form-section-description text-sm {{@class}}">
    {{yield}}
  </p>
  */
  {
    id: "hqVqsm2f",
    block: "{\"symbols\":[\"@class\",\"&default\"],\"statements\":[[7,\"p\",true],[11,\"class\",[29,[\"form-section-description text-sm \",[23,1,[]]]]],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/form/section-description.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});