define("mvcs/pages/organization/team/page/invite-new/component", ["exports", "@glimmer/component", "mvcs/validators/invite"], function (_exports, _component, _invite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrganizationInviteNew = (_class = (_temp = class OrganizationInviteNew extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "events", _descriptor3, this);

      _defineProperty(this, "inviteValidations", _invite.default);

      _defineProperty(this, "inviteRoles", ['Member', 'Admin']);

      _defineProperty(this, "inviteModel", null);

      this.newInviteFactory();
    }

    newInviteFactory() {
      const invite = this.store.createRecord('invite', {
        name: null,
        email: null,
        status: 'unclaimed',
        role: 'Member'
      });
      Ember.set(this, 'inviteModel', invite);
    }

    onSubmitSuccess(invite) {
      this.notifications.success('New User Invited!');
      this.events.trackEvent('Form Invite / New Submitted');
      this.args.onInviteAdd(invite);
      this.newInviteFactory();
    }

    onServerError(response) {
      const {
        errors
      } = response;

      if (errors[0] && errors[0]['detail']) {
        this.notifications.danger(errors[0].detail);
      } else {
        this.notifications.danger('An unexpected error occurred');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "events", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitSuccess", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onServerError", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onServerError"), _class.prototype)), _class);
  _exports.default = OrganizationInviteNew;
});