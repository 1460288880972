define("mvcs/router", ["exports", "mvcs/config/environment", "ember-intercom-io/mixins/intercom-route"], function (_exports, _environment, _intercomRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_intercomRoute.default, {
    router: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('home', {
      path: '/'
    });
    this.route('anon.login', {
      path: '/login'
    });
    this.route('anon.logout', {
      path: '/logout'
    });
    this.route('invite-accept', {
      path: 'invite'
    });
    this.route('onboarding', {
      path: 'signup'
    }, function () {
      this.route('organization');
    });
    this.route('dashboard');
    this.route('vehicles', {}, function () {
      this.route('index', {
        path: ''
      });
      this.route('detail', {
        path: ':id'
      });
      this.route('edit', {
        path: ':id/edit'
      });
    });
    this.route('events', {}, function () {
      this.route('index', {
        path: ''
      });
      this.route('detail', {
        path: ':id'
      });
    });
    this.route('organization', {}, function () {
      this.route('index', {
        path: ''
      });
      this.route('team');
    });
    this.route('user');
    this.route('error');
    this.route('not-found', {
      path: '*'
    });
  });
  var _default = Router;
  _exports.default = _default;
});