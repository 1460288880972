define("mvcs/pages/vehicles/index/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5kCXH6yJ",
    "block": "{\"symbols\":[\"device\",\"@devices\"],\"statements\":[[5,\"app-title/simple\",[],[[],[]],{\"statements\":[[0,\"\\n  Vehicles\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"app-container\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"card-deck project-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[5,\"vehicles/index/page/device\",[],[[\"@device\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"vehicles/index/page/empty\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/index/page/template.hbs"
    }
  });

  _exports.default = _default;
});