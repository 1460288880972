define("mvcs/pages/anon/login/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LSxfmc/3",
    "block": "{\"symbols\":[\"Page\"],\"statements\":[[5,\"layout/anon-page\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"left\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layout/anon-logo\",[],[[],[]]],[0,\"\\n    \"],[5,\"anon/login/page/form\",[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"right\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"p-10\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"font-weight-bold\"],[8],[0,\"\\n        Welcome!\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        Welcome to Mobile Video Computing Solutions!\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        MVCS integrates video, telematics, and management information systems, under the brand name CruiseCam, to create fleet solutions for the Tow & Recovery, Emergency Medical Services, Waste Management, and Crash Tow Claims markets.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/anon/login/page/template.hbs"
    }
  });

  _exports.default = _default;
});