define("mvcs/routes/abstract/abstract-authenticated", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AbstractAuthenticatedRoute extends Ember.Route.extend(_authenticatedRouteMixin.default) {}

  _exports.default = AbstractAuthenticatedRoute;
});