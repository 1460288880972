define("mvcs/services/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EventService extends Ember.Service {
    /**
     *
     * @param userId
     */
    alias(userId) {
      analytics.alias(userId);
    }
    /**
     *
     * @param userData
     * @param organizationData
     * @param options
     */


    identify(userData, organizationData, options = {}) {
      analytics.identify(userData.id, userData.traits, options);
    }
    /**
     *
     * @param eventName
     * @param data
     * @param options
     */


    trackEvent(eventName, data, options = {}) {
      analytics.track(eventName, data, options);
    }
    /**
     *
     * @param name
     * @param category
     * @param properties
     * @param options
     */


    trackPage(name, category = null, properties = {}, options = {}) {
      analytics.page(category, name, properties, options);
    }

  }

  _exports.default = EventService;
});