define("mvcs/services/models", ["exports", "eventemitter3"], function (_exports, _eventemitter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModelService = (_class = (_temp = class ModelService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "pubnub", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _defineProperty(this, "models", Ember.A());

      _defineProperty(this, "emitter", new _eventemitter.default());

      this.pubnub.registerMessageListener(`organization-events-${this.currentUser.organization.id}`, this.handleNewModel);
    }

    handleNewModel({
      message
    }) {
      if (message === null || message === void 0 ? void 0 : message.model) {
        let model = this.store.push({
          data: [message.model]
        });
        this.models.addObject(model[0]);
        this.emitter.emit('newEvent', model);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pubnub", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleNewModel", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNewModel"), _class.prototype)), _class);
  _exports.default = ModelService;
});