define("mvcs/components/layout/anon-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="logo">
    <img src="/images/logo.png" alt="MVCS" class="img-fluid" />
    <span class="sr-only">
      MVCS
    </span>
  </div>
  */
  {
    id: "RV+R7lQX",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"logo\"],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"/images/logo.png\"],[10,\"alt\",\"MVCS\"],[10,\"class\",\"img-fluid\"],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[0,\"\\n    MVCS\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/layout/anon-logo.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});