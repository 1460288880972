define("mvcs/components/layout/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="d-flex flex-row app-height">
    <Layout::App::Sidebar />
    <Layout::App::Content>
      {{outlet}}
    </Layout::App::Content>
    <Layout::App::ModelPanel />
  </div>
  */
  {
    id: "U4PVoCME",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex flex-row app-height\"],[8],[0,\"\\n  \"],[5,\"layout/app/sidebar\",[],[[],[]]],[0,\"\\n  \"],[5,\"layout/app/content\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"layout/app/model-panel\",[],[[],[]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/layout/app.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});