define("mvcs/pages/user/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6kv7CuH+",
    "block": "{\"symbols\":[\"@profile\",\"@user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container container-xs profile\"],[8],[0,\"\\n  \"],[5,\"user/page/profile\",[],[[\"@profile\",\"@user\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/user/page/template.hbs"
    }
  });

  _exports.default = _default;
});