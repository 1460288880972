define("mvcs/pages/events/detail/page/images/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CCbzHSlM",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row mt-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"\\n      Images\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isMediaLoading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        Loading...\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isMediaEmpty\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        No Images Found...\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"card-deck project-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"media\"]]],null,{\"statements\":[[0,\"          \"],[5,\"image\",[[12,\"alt\",\"media image\"]],[[\"@url\"],[[23,1,[\"url\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/events/detail/page/images/template.hbs"
    }
  });

  _exports.default = _default;
});