define("mvcs/pages/user/page/profile/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountProfileComponent extends _component.default {}

  _exports.default = AccountProfileComponent;
});