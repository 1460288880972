define("mvcs/pages/onboarding/organization/page/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rHanZDEv",
    "block": "{\"symbols\":[\"Provider\",\"Form\",\"Card\"],\"statements\":[[5,\"changeset-provider\",[],[[\"@onSubmitSuccess\",\"@onServerError\",\"@validator\",\"@model\"],[[28,\"action\",[[23,0,[]],[23,0,[\"onSubmitSuccess\"]]],null],[23,0,[\"onServerError\"]],[23,0,[\"validator\"]],[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[23,1,[\"changeset\"]],[23,0,[\"submit\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-card\",[[12,\"class\",\"rounded shadow-sm overflow-hidden p-3\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"body\"]],[[12,\"class\",\"p-1\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,2,[\"element\"]],[],[[\"@property\",\"@label\"],[\"name\",\"Organization Name\"]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,3,[\"footer\"]],[[12,\"class\",\"p-1\"]],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"bs-button\",[],[[\"@type\",\"@buttonType\"],[\"primary\",\"submit\"]],{\"statements\":[[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"save\"]]],[0,\"\\n          Next\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/onboarding/organization/page/form/template.hbs"
    }
  });

  _exports.default = _default;
});