define("mvcs/helpers/split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function split([str = '', separator = ',', limit = undefined]) {
    if (!str) {
      return [];
    }

    if (Ember.typeOf(str.split) !== 'function') {
      return [];
    }

    return str.split(separator, limit);
  });

  _exports.default = _default;
});