define("mvcs/components/device/badge-online", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @isOnline}}
    <span class="badge badge-success mr-2">
      ON
    </span>
  {{else}}
    <span class="badge badge-danger mr-2">
      OFF
    </span>
  {{/if}}
  */
  {
    id: "MG0q63QP",
    block: "{\"symbols\":[\"@isOnline\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"badge badge-success mr-2\"],[8],[0,\"\\n    ON\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"badge badge-danger mr-2\"],[8],[0,\"\\n    OFF\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/device/badge-online.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});