define("mvcs/components/form/section-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h5 class="form-section-title {{@class}}">
    {{yield}}
  </h5>
  */
  {
    id: "RUwVrDH7",
    block: "{\"symbols\":[\"@class\",\"&default\"],\"statements\":[[7,\"h5\",true],[11,\"class\",[29,[\"form-section-title \",[23,1,[]]]]],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/form/section-title.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});