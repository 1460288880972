define("mvcs/pages/invite-accept/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ERPhCO6n",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"invite-accept/page\",[],[[\"@invite\",\"@error\"],[[23,1,[\"invite\"]],[23,1,[\"error\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/invite-accept/template.hbs"
    }
  });

  _exports.default = _default;
});