define("mvcs/pages/organization/team/page/invite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IRZYATmM",
    "block": "{\"symbols\":[\"@invite\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"w-50\"],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      \"],[1,[23,1,[\"email\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"my-0 small text-grey-400 text-uppercase\"],[8],[0,\"\\n    \"],[1,[28,\"moment-from\",[[23,1,[\"created\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"default\",[28,\"action\",[[23,0,[]],[23,0,[\"resendInvite\"]],[23,1,[]]],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"envelope\",\"fal\"]]],[0,\"\\n    Resend\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"confirm-button\",[],[[\"@type\",\"@buttonText\",\"@modalTitle\",\"@onSubmit\"],[\"danger\",\"Revoke Invite\",\"Revoke Invite?\",[28,\"action\",[[23,0,[]],\"revokeInvite\",[23,1,[]]],null]]],{\"statements\":[[0,\"\\n    Revoking the invite for\\n    \"],[1,[23,1,[\"email\"]],false],[0,\"\\n    will prevent them from logging in.\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/organization/team/page/invite/template.hbs"
    }
  });

  _exports.default = _default;
});