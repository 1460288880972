define("mvcs/components/events/list/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h5 class="text-center p-4">
    No Events Found.
  </h5>
  */
  {
    id: "Rx9ZigHm",
    block: "{\"symbols\":[],\"statements\":[[7,\"h5\",true],[10,\"class\",\"text-center p-4\"],[8],[0,\"\\n  No Events Found.\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mvcs/components/events/list/empty.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});