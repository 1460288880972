define("mvcs/initializers/ember-intercom", ["exports", "ember-intercom-io/initializers/ember-intercom"], function (_exports, _emberIntercom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberIntercom.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _emberIntercom.initialize;
    }
  });
});