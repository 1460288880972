define("mvcs/pages/vehicles/index/page/empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KdQs0jtp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"\\n  No Vehicles Found.\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/vehicles/index/page/empty/template.hbs"
    }
  });

  _exports.default = _default;
});