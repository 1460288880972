define("mvcs/pages/home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pyfu2ycc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center m-2\"],[8],[0,\"\\n  \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\",\"@size\"],[\"cocktail\",\"fal\",\"4x\"]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n    Going places...\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/home/template.hbs"
    }
  });

  _exports.default = _default;
});