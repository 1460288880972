define("mvcs/pages/anon/logout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AL0IURih",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center m-2\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        you are already logged in.\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n        \"],[5,\"link-to\",[[12,\"class\",\"btn btn-primary\"]],[[\"@route\"],[\"dashboard\"]],{\"statements\":[[0,\"\\n          Back to dashboard\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        You've been logged out!\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n        \"],[5,\"link-to\",[[12,\"class\",\"btn btn-primary\"]],[[\"@route\"],[\"anon.login\"]],{\"statements\":[[0,\"\\n          Sign In Again\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/anon/logout/template.hbs"
    }
  });

  _exports.default = _default;
});