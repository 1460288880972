define("mvcs/pages/events/detail/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TUXArM6H",
    "block": "{\"symbols\":[\"Title\",\"@event\"],\"statements\":[[5,\"app-title/advanced\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[\"type\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"secondary\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[5,\"app-container\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"events/detail/page/video\",[],[[\"@event\"],[[23,2,[]]]]],[0,\"\\n  \"],[5,\"events/detail/page/images\",[],[[\"@event\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvcs/pages/events/detail/page/template.hbs"
    }
  });

  _exports.default = _default;
});